import { createSelector } from 'reselect';

import { IChatTopic } from '../../pages/chat';
import { IPage, IService } from '../../types';

export interface IAppState {
  unreadCount: number;
  nickname: string;
  topicList: Record<string, IChatTopic>;
  token: string | null;
  userID: number | null;
  categoryList: Record<number, string>;
  serviceList: IService[];
  service: IPage | null;
  payload: { name: string | null; surname: string | null; avatar: string | null };
}

export const initialState: IAppState = {
  unreadCount: 0,
  nickname: '',
  topicList: {},
  token: null,
  userID: null,
  categoryList: {},
  serviceList: [],
  service: null,
  payload: { name: null, surname: null, avatar: null }
};

export const selectState = (state: { app: IAppState }) => state.app;

export const selectUnreadCount = createSelector(selectState, state => state.unreadCount);

export const selectTopicList = createSelector(selectState, state => state.topicList);

export const selectToken = createSelector(selectState, state => state.token);

export const selectUserID = createSelector(selectState, state => state.userID);

export const selectCategoryList = createSelector(selectState, state => state.categoryList);

export const selectServiceList = createSelector(selectState, state => state.serviceList);

export const selectService = createSelector(selectState, state => state.service);

export const selectPayload = createSelector(selectState, state => state.payload);
