import React, { PureComponent, RefObject } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IElement } from '../../../types';
import { Element } from '../Element';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  value: IElement;
  onRef: (id: string, title: string | null, ref: RefObject<HTMLDivElement>) => void;
  onOpenChat: () => void;
}

interface State {
  collapsed: boolean;
}

export class CollapsibleViewWithTitle extends PureComponent<Props, State> {
  ref: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.ref = React.createRef();
    this.props.onRef(this.props.value.id, this.props.value.title, this.ref);
  }

  render() {
    return (
      <Box ref={this.ref} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box
          sx={{
            borderWidth: '1px',
            borderColor: 'transparent',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <Typography sx={{ fontSize: '22px', fontWeight: '500', lineHeight: '28px' }}>
            {this.props.value.title}
          </Typography>
          {this.state.collapsed ? (
            <ArrowDropDownIcon
              onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))}
              sx={{
                ':hover': {
                  cursor: 'pointer'
                }
              }}
            />
          ) : (
            <ArrowDropUpIcon
              onClick={() => this.setState(prev => ({ collapsed: !prev.collapsed }))}
              sx={{
                ':hover': {
                  cursor: 'pointer'
                }
              }}
            />
          )}
        </Box>
        <Box sx={{ marginLeft: 2 }}>
          {!this.state.collapsed &&
            this.props.value.children.map(elem => (
              <Element key={elem.id} value={elem} onRef={this.props.onRef} onOpenChat={this.props.onOpenChat} />
            ))}
        </Box>
      </Box>
    );
  }
}
