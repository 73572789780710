import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

import { TopicIcon } from '../TopicIcon';

interface Props {
  selected: boolean;
  topic: string;
  author: string | null;
  message: string | null;
  time: string | null;
  unreadCount: number;
  onClick: () => void;
}

export class TopicRow extends PureComponent<Props> {
  render() {
    const { selected, topic, author, message, time, unreadCount, onClick } = this.props;
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: selected ? '#526ED3' : '#ffffff',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={onClick}>
        <TopicIcon name={cTopicAliasToName[topic]} />

        <Box sx={{ ml: 2, display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 700,
              fontSize: '15px',
              lineHeight: '24px',
              color: selected ? '#ffffff' : '#1B1F3B',
              opacity: selected ? 1 : 0.8
            }}>
            {`${cTopicAliasToName[topic]}(${topic})`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              maxWidth: '300px'
            }}>
            {author != null && (
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '24px',
                  color: selected ? '#ffffff' : '#526ED3',
                  width: 'fit-content',
                  whiteSpace: 'nowrap'
                }}>
                {author}:&nbsp;
              </Typography>
            )}
            {message != null && (
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '24px',
                  color: selected ? '#ffffff' : '#1B1F3B',
                  opacity: selected ? 1 : 0.65,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {message}
              </Typography>
            )}
          </Box>
        </Box>
        {time != null && (
          <Box sx={{ ml: 2, minWidth: '79px' }}>
            <Typography
              sx={{
                textAlign: 'end',
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '24px',
                color: selected ? '#ffffff' : '#1B1F3B',
                opacity: selected ? 1 : 0.4
              }}>
              {time}
            </Typography>
            {unreadCount > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: selected ? '#FFFFFF' : '#526ED3',
                  mr: '0px',
                  ml: '49px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {unreadCount}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }
}

const cTopicAliasToName: Record<string, string> = {
  'spain-common': 'Первые шаги в Испании',
  'spain-06': 'Открытие индивидуального банковского счёта по доверенности для граждан РФ',
  'spain-07':
    'Открытие индивидуального банковского счёта при личном присутствии для граждан бывших республик СССР, кроме РФ и РБ',
  'spain-08':
    'Открытие индивидуального банковского счёта при личном присутствии для граждан Российской Федерации и Республики Беларусь',
  'spain-09':
    'Открытие семейного банковского счёта при личном присутствии для граждан бывших республик СССР, кроме РФ и РБ',
  'spain-010':
    'Открытие семейного банковского счёта при личном присутствии для граждан Российской Федерации и Республики Беларусь',
  'spain-10': 'Оформление декларации о въезде в страну',
  'spain-11': 'Оформление декларации о ввозе наличных средств на территорию Испании',
  'spain-12': 'Оформление идентификационного номера иностранца',
  'spain-13': 'Оформление идентификационного номера иностранца ОДНОВРЕМЕННО для двоих',
  'spain-14': 'Присяжные переводы',
  'spain-15': 'Прописка',
  'spain-16': 'Получение CITA',
  'spain-20': 'Подбор риелтора',
  'spain-30': 'Создание Индивидуального Предпринимательства',
  'spain-31': 'Создание Общества с ограниченной ответственностью',
  'spain-32': 'Финансовый консультант',
  'spain-33': 'Дистанционное изготовление ЭЦП',
  'spain-34': 'Юридический консультант',
  'spain-40': 'Языковые курсы',
  'spain-41': 'Омологация дипломов и аттестатов в Испании',
  'spain-50': 'Подбор школы в Испании',
  'spain-51': 'Подбор детского сада в Испании',
  'spain-52': 'Получения свидетельства о рождении ребёнка в Испании',
  'spain-60': 'Перевозка автомобиля',
  'spain-61': 'Перерегистрация автомобиля в Испании',
  'spain-62': 'Прокат скутеров и мотоциклов',
  'spain-63': 'Прокат автомобилей',
  'spain-64': 'Перевозка грузов и личных вещей из России',
  'spain-70': 'Услуги переводчика',
  'spain-71': 'Подбор страхового агента',
  'france-common': 'Первые шаги во Франции',
  'france-00': 'Открытие индивидуального банковского счёта при личном присутствии',
  'france-01': 'Открытие семейного банковского счёта при личном присутствии',
  'france-10': 'Оформление всех видов страхования',
  'france-11': 'Оформление Французского налогового номера',
  'france-12': 'Присяжные переводы на французский язык',
  'france-13': 'Слоты на прописку, в полицию',
  'france-14': 'Оформление деклараций',
  'france-15': 'Апостиль',
  'france-20': 'Замена водительских прав',
  'france-30': 'Запись к врачу и сопровождение в медучреждения',
  'france-31': 'Оформление Carte Vitale',
  'france-40': 'Сопровождение в различные учреждения Франции',
  'france-41': 'Сопровождение и помощь переводчика с различными услугами',
  'france-50': 'Оформление студенческой резиденции',
  'france-60': 'Подбор детского садика',
  'france-61': 'Подбор школы',
  'france-62': 'Подбор университета'
};
