import React, { PureComponent } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

interface Props {
  navigate: NavigateFunction;
  href: string;
  disabled: boolean;
}

class ButtonNextConnect extends PureComponent<Props> {
  render() {
    const { href, disabled } = this.props;
    return (
      <Button
        disabled={disabled}
        onClick={() => this.props.navigate(`${href}`)}
        // disabled={!this.state.isChecked}
        sx={{
          width: '343px',
          height: '56px',
          '&.Mui-disabled': {
            background: '#F3F6FA',
            color: '#661B1F3B'
          },
          background: '#ED6D42',
          color: '#FFFFFF',
          fontSize: '17px',
          fontWeight: 500,
          ':hover': {
            backgroundColor: '#ED6D42'
          },
          borderRadius: '10px',
          mt: '15px'
        }}>
        Дальше
      </Button>
    );
  }
}

export const ButtonNext = (props: { href: string; disabled: boolean }) => {
  const navigate = useNavigate();
  return <ButtonNextConnect navigate={navigate} {...props} />;
};
