import React, { PureComponent } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

interface Props {
  navigate: NavigateFunction;
}

class HeaderBarUnauthorizedConnect extends PureComponent<Props> {
  render() {
    return (
      <AppBar
        position="static"
        color="transparent"
        sx={{
          borderBottomWidth: '1px',
          borderColor: '#D9D9D9',
          borderBottomStyle: 'solid',
          boxShadow: 'none',
          height: '80px',
          mb: '58px'
        }}>
        <Toolbar sx={{ height: '100%' }}>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              ml: 0
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ':hover': {
                  cursor: 'pointer'
                }
              }}>
              <Box
                onClick={() => this.props.navigate(`/`)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '157px'
                }}>
                <img src="/images/logo.png" width="25px" height="25px" alt={'logo'} />
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '36px',
                    alignSelf: 'center',
                    textTransform: 'none',
                    ml: 1,
                    color: '#1B1F3B'
                  }}>
                  Let's Go
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.5,
                    fontSize: '12px',
                    ml: '11px',
                    mt: '5px',
                    color: '#1B1F3B'
                  }}>
                  {process.env.REACT_APP_VERSION}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    );
  }
}

export const HeaderBarUnauthorized = () => {
  const navigate = useNavigate();

  return <HeaderBarUnauthorizedConnect navigate={navigate} />;
};
