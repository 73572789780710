import { Component, RefObject, useContext } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { HeaderBar } from '../../features/HeaderBar';
import { useNavigate, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { IPage } from '../../types';
import { Element } from '../../features/service/Element';
import { useDispatch, useSelector } from 'react-redux';
import { selectService } from '../../app/redux/state';
import { refreshService } from '../../app/redux/actions';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { sendAnalyticsEvent } from '../../service/analytics';
import { LocalizationContext } from '../../features/Localization';

interface Props {
  id: string | undefined;
  service: null | IPage;
  language: string;
  navigate: NavigateFunction;
  actions: {
    refreshService: (value: number, language: string) => void;
  };
}

interface State {
  collapseViewRefList: Record<string, { title: string | null; ref: RefObject<HTMLDivElement> }>;
}

class ServiceConnect extends Component<Props, State> {
  preCollapseViewRefList: Record<string, { title: string | null; ref: RefObject<HTMLDivElement> }>;

  constructor(props: Props) {
    super(props);
    this.state = {
      collapseViewRefList: {}
    };
    this.preCollapseViewRefList = {};
  }

  onRef = (id: string, title: string | null, ref: RefObject<HTMLDivElement>) =>
    (this.preCollapseViewRefList = {
      ...this.preCollapseViewRefList,
      [id]: { title, ref }
    });

  onOpenChat = () => {
    sendAnalyticsEvent('bGetService', { id: this.props.service?.topic, name: this.props.service?.name });
    this.props.navigate(`/support/${this.props.service?.topic}`);
  };

  componentDidMount() {
    if (this.props.id != null) {
      this.props.actions.refreshService(parseInt(this.props.id, 10), this.props.language);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (Object.keys(this.preCollapseViewRefList).length > 0) {
      const collapseViewRefList = cloneDeep(this.preCollapseViewRefList);
      this.setState(() => ({ collapseViewRefList }));
      this.preCollapseViewRefList = {};
    }
  }

  render() {
    const { service } = this.props;
    if (service == null) {
      return null;
    }
    return (
      <Container maxWidth={false} disableGutters>
        <HeaderBar />
        <Container
          maxWidth={false}
          style={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Grid container sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Grid item xs={3} md={3} lg={3} sx={{ minWidth: '298px', margin: '32px 0px 5px 24px' }}>
              <Box
                sx={{
                  mb: 1,
                  padding: '2px 24px',
                  ':hover': {
                    cursor: 'pointer'
                  }
                }}>
                {Object.keys(this.state.collapseViewRefList).map(key => (
                  <Typography
                    onClick={() => this.state.collapseViewRefList[key].ref.current!.scrollIntoView()}
                    key={`Typography-${key}`}
                    sx={{
                      textAlign: 'left',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '24px',
                      color: '#1B1F3B',
                      opacity: 0.8,
                      mb: '8px'
                    }}>
                    {this.state.collapseViewRefList[key].title}
                  </Typography>
                ))}
                {/*  sx={{*/}
                {/*    textAlign: 'left',*/}
                {/*    fontWeight: 400,*/}
                {/*    fontSize: '15px',*/}
                {/*    lineHeight: '24px',*/}
                {/*    color: '#1B1F3B',*/}
                {/*    opacity: 0.4,*/}
                {/*    mb: '8px'*/}
                {/*  }}>*/}
                {/*  Как выглядит процедура открытия счёта?*/}
                {/*</Typography>*/}
              </Box>
            </Grid>
            <Grid item xs={9} md={9} lg={9} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 80px)', pb: '20px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', pl: '5%', pt: 2, width: '50%' }}>
                {service.children.map(elem => (
                  <Element key={elem.id} value={elem} onRef={this.onRef} onOpenChat={this.onOpenChat} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

export const Service = () => {
  const { id } = useParams();
  const service = useSelector(selectService);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(LocalizationContext);

  return (
    <ServiceConnect
      id={id}
      service={service}
      language={context.language}
      navigate={navigate}
      actions={{ refreshService: (value: number, language: string) => dispatch(refreshService(value, language)) }}
    />
  );
};
