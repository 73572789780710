import React, { PureComponent } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';
import { CheckBoxButtonGroup } from '../../../features/CheckBoxButtonGroup';
import { HeaderBarUnauthorized } from '../../../features/HeaderBarUnauthorized';
import { ProgressBar } from '../../../features/ProgressBar';
import { ButtonNext } from '../../../features/ButtonNextQuestionnaire';

interface Props {
  navigate: NavigateFunction;
}

interface State {
  isEnableNext: boolean;
}

class Questionnaire4Connect extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEnableNext: false
    };
  }

  onSelect = (data: (string | undefined)[]) => this.setState(() => ({ isEnableNext: data.length > 0 }));

  render() {
    return (
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh'
        }}>
        <HeaderBarUnauthorized />
        <ProgressBar current={5} count={5} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '36px',
            lineHeight: '40px',
            color: '#1B1F3B',
            opacity: 0.8,
            mb: '24px',
            mt: '40px'
          }}>
          Каким составом вы переезжаете?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <CheckBoxButtonGroup
            data={['Один/одна', 'Со второй половиной', 'С детьми', 'С животными', 'Перевожу сотрудников']}
            onSelect={this.onSelect}
          />
          <ButtonNext href={'/login'} disabled={!this.state.isEnableNext} />
        </Box>
      </Container>
    );
  }
}

export const Questionnaire4 = () => {
  const navigate = useNavigate();
  return <Questionnaire4Connect navigate={navigate} />;
};
