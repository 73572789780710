import { PureComponent, useContext } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { TopicIconItemList } from '../../features/TopicIconItemList';
import { selectCategoryList, selectPayload } from '../../app/redux/state';
import { refreshCategoryList } from '../../app/redux/actions';
import { HeaderBar } from '../../features/HeaderBar';
import { sendAnalyticsEvent } from '../../service/analytics';
import { ITranslate } from '../../features/Localization/types';
import { LocalizationContext, useTranslate } from '../../features/Localization';

interface Props {
  translate: ITranslate;
  categoryList: Record<number, string>;
  navigate: NavigateFunction;
  payload: { name: string | null; surname: string | null; avatar: string | null };
  language: string;
  actions: {
    refreshCategoryList: (language: string) => void;
  };
}

class MainConnect extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.categoryList).length === 0) {
      this.props.actions.refreshCategoryList(this.props.language);
    }
  }

  openIntro = () => {
    sendAnalyticsEvent('bFirstSteps');
    this.props.navigate('/service/31');
  };

  openCategory = (id: number, name: string) => {
    sendAnalyticsEvent('bClickCategory', { id, name });
    this.props.navigate(`/list/${id}`);
  };

  render() {
    const { translate } = this.props;
    return (
      <Container
        maxWidth={false}
        style={{
          padding: 0,
          margin: 0,
          display: 'flex',
          flexDirection: 'column'
          // width: '100vw',
        }}>
        <Box
          sx={{
            width: '100%',
            aspectRatio: '3/1',
            background: 'url(images/img.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
          <HeaderBar />
          <Typography
            sx={{
              width: '855px',
              fontSize: '72px',
              lineHeight: '86px',
              fontWeight: '500',
              color: '#FFFFFF',
              mt: '4%',
              ml: '116px'
            }}>
            {translate('desktop_first_steps')}
          </Typography>
          <Button variant={'contained'} sx={{ mt: '56px', ml: '116px' }} onClick={this.openIntro}>
            <Typography sx={{ color: '#1B1F3B', opacity: 0.85, textAlign: 'start' }}>
              {translate('desktop_what_will_required')}
            </Typography>
            <Box
              sx={{ backgroundImage: 'url(images/arrow-left.svg)', width: '24px', height: '24px', ml: '95px' }}></Box>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, width: '921px' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '24px',
              color: '#1B1F3B',
              opacity: 0.65,
              ml: '116px',
              mt: '24px',
              mb: '16px'
            }}>
            {translate('desktop_select_instruction')}
          </Typography>
          <TopicIconItemList data={this.props.categoryList} onClick={this.openCategory} />
        </Box>
      </Container>
    );
  }
}

export const Main = () => {
  const navigate = useNavigate();
  const categoryList = useSelector(selectCategoryList);
  const payload = useSelector(selectPayload);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const context = useContext(LocalizationContext);

  return (
    <MainConnect
      translate={translate}
      categoryList={categoryList}
      navigate={navigate}
      payload={payload}
      language={context.language}
      actions={{ refreshCategoryList: (language: string) => dispatch(refreshCategoryList(language)) }}
    />
  );
};
