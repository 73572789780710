import React, { PureComponent } from 'react';
import { Box, IconButton, SvgIcon } from '@mui/material';

interface Props {
  current: number;
  count: number;
}

export class ProgressBar extends PureComponent<Props> {
  render() {
    const { current, count } = this.props;
    return (
      <Box sx={{ width: '715px', display: 'flex', alignItems: 'flex-start', height: '22px' }}>
        <IconButton sx={{ p: 0, mr: '15px', ':hover': { background: 'none' } }}>
          <SvgIcon
            sx={{
              backgroundImage: 'url(/images/arrow-back.svg)',
              width: '12px',
              height: '21px'
            }}></SvgIcon>
        </IconButton>
        <Box
          sx={{
            width: '702px',
            height: '6px',
            background: '#F3F6FA',
            borderRadius: '10px',
            mt: '7px'
          }}>
          <Box
            sx={{
              width: `${(100 * current) / count}%`,
              background: '#ED6D42',
              height: '100%',
              borderRadius: '10px'
            }}></Box>
        </Box>
      </Box>
    );
  }
}
