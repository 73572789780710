import { PureComponent } from 'react';
import { Box } from '@mui/material';
import { TopicItem } from '../TopicItem';
import { IService } from '../../types';

interface Props {
  data: IService[];
  onClick: (id: number, name: string) => void;
}

export class TopicItemList extends PureComponent<Props> {
  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap'
        }}>
        {this.props.data.map(obj => (
          <TopicItem key={obj.id} value={obj.name} onClick={() => this.props.onClick(obj.id, obj.name)} />
        ))}
      </Box>
    );
  }
}
