import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IElement, ITextElement } from '../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class DocBoxWithText extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: '40px',
              height: 40,
              background: '#F3F6FA',
              borderRadius: 5,
              marginRight: '8px',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <DescriptionOutlinedIcon />
          </Box>
          <Typography sx={{ fontSize: '17px', lineHeight: '24px' }}>{this.props.value.title}</Typography>
        </Box>
        <Typography sx={{ ml: '48px', mt: '5px', fontSize: '15px', lineHeight: '20px' }}>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
