import React, { PureComponent } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { Message } from './components/Message';
import { IChatTopic } from '../../pages/chat';

interface Props {
  selectedTopic: string | null;
  data: Record<string, IChatTopic>;
  onSend: (value: string) => void;
}

interface State {
  offset: number;
  value: string;
}

export class MessageListView extends PureComponent<Props, State> {
  messagesEndRef = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      offset: 64, //23(textarea) + (5 + 4)(TextField padding) + (16 * 2)(Box padding)
      value: ''
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    // @ts-ignore
    this.messagesEndRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  // magic const from inspect page.
  onChange = (event: any) =>
    this.setState(() => ({ value: event.target.value, offset: event.target.scrollHeight + (64 - 23) }));

  onSend = () => {
    this.props.onSend(this.state.value);
    this.setState(() => ({ value: '' }));
  };

  render() {
    const { offset } = this.state;
    const { selectedTopic, data } = this.props;
    const topic: IChatTopic | null = selectedTopic != null ? data[selectedTopic] : null;
    return topic != null ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          background: '#F3F6FA',
          borderLeftWidth: '1px',
          borderColor: '#D9D9D9',
          borderLeftStyle: 'solid',
          minHeight: '100%'
        }}>
        <Box
          sx={{
            height: `calc(100vh - 80px - ${offset}px)`,
            overflow: 'auto'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }} ref={this.messagesEndRef}>
            {topic.data.map(message => (
              <Message
                key={`Message-${message.id}`}
                colorIndex={0}
                author={message.author}
                message={message.value}
                created={message.time}
                root={message.root}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            pl: 2,
            pr: 2,
            pt: 2,
            pb: 2,
            backgroundColor: '#ffffff',
            alignItems: 'flex-end'
          }}>
          <TextField
            multiline
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            onChange={this.onChange}
            value={this.state.value}
            minRows={1}
            id="message-input"
            placeholder="Введите сообщение"
            sx={{ border: 'none' }}
            style={{
              width: '100%',
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '24px',
              border: 'none',
              outline: 0,
              color: '#1B1F3B',
              opacity: 0.8
            }}
          />
          <IconButton
            onClick={this.onSend}
            color="primary"
            aria-label="add an alarm"
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: '#ED6D42',
              ':hover': {
                backgroundColor: '#ED6D42'
              }
            }}>
            <ArrowUpwardIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          background: '#F3F6FA',
          borderLeftWidth: '1px',
          borderColor: '#D9D9D9',
          borderLeftStyle: 'solid'
        }}></Box>
    );
  }
}
