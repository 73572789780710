import { PureComponent } from 'react';
import { TopicIconItem } from '../TopicIconItem';
import { Box } from '@mui/material';

interface Props {
  data: Record<number, string>;
  onClick: (id: number, name: string) => void;
}

export class TopicIconItemList extends PureComponent<Props> {
  render() {
    const { data } = this.props;
    return (
      <Box sx={{ display: 'flex', flex: 1, flexWrap: 'wrap', ml: '116px', width: '1041px', mb: '12px' }}>
        {Object.keys(data).map(key => (
          <TopicIconItem
            key={key}
            onClick={() => this.props.onClick(key as unknown as number, data[key as unknown as number])}
            title={data[key as unknown as number]}
          />
        ))}
      </Box>
    );
  }
}
