import React, { PureComponent } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { IElement, ITextElement } from '../../../types';
import { Bold } from '../Bold';
import { LocalizationContext } from '../../Localization';

interface Props {
  value: IElement;
  onOpenChat: () => void;
}

export class CostBox extends PureComponent<Props> {
  render() {
    return (
      <LocalizationContext.Consumer>
        {/*@ts-ignore*/}
        {({ getWord }) => (
          <Box sx={{ width: 'fit-content' }}>
            <Box
              sx={{
                width: 'fit-content',
                padding: '12px 16px',
                background: '#F3F6FA',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center'
              }}>
              <Typography
                sx={{
                  fontSize: '17px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  opacity: 0.65,
                  mr: '10px'
                }}>
                {getWord('desktop_service_cost')}
              </Typography>
              <Typography sx={{ fontSize: '22px', fontWeight: '700', color: '#1B1F3B', opacity: 0.8 }}>
                {' '}
                {this.props.value.text.map((txt: ITextElement) =>
                  txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
                )}
              </Typography>
            </Box>
            <Button
              onClick={this.props.onOpenChat}
              sx={{
                width: '100%',
                height: '44px',
                backgroundColor: '#ED6D42',
                color: '#FFFFFF',
                ':hover': {
                  backgroundColor: '#ED6D42'
                },
                boxShadow: '0px 2px 5px #DAC8C8;',
                borderRadius: '10px',
                mt: '8px'
              }}>
              {getWord('desktop_get_service')}
            </Button>
          </Box>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
