import { REHYDRATE } from 'redux-persist/es/constants';
import { initialState } from './state';
import {
  APP_UPDATE_CATEGORY_LIST,
  APP_UPDATE_PAYLOAD,
  APP_UPDATE_SERVICE,
  APP_UPDATE_SERVICE_LIST,
  APP_UPDATE_TOKEN,
  APP_UPDATE_TOPIC_LIST,
  APP_UPDATE_UNREAD_COUNT,
  APP_UPDATE_USER_ID,
  IAppActionTypes
} from './actions';
import { has } from 'lodash';

export default function appReducer(state = initialState, action: IAppActionTypes) {
  let result = { ...state };
  switch (action.type) {
    case REHYDRATE: {
      if (has(action.payload, 'app')) {
        result = { ...action.payload.app, categoryList: {}, serviceList: [], service: null };
      }
      break;
    }
    case APP_UPDATE_TOPIC_LIST: {
      result = {
        ...state,
        topicList: { ...action.payload.data }
      };
      break;
    }
    case APP_UPDATE_USER_ID: {
      const { value } = action.payload;
      result = {
        ...state,
        userID: value
      };
      break;
    }
    case APP_UPDATE_CATEGORY_LIST: {
      const { data } = action.payload;
      result = {
        ...state,
        categoryList: { ...data }
      };
      break;
    }
    case APP_UPDATE_SERVICE_LIST: {
      const { data } = action.payload;
      result = {
        ...state,
        serviceList: [...data]
      };
      break;
    }
    case APP_UPDATE_SERVICE: {
      const { value } = action.payload;
      result = {
        ...state,
        service: value
      };
      break;
    }
    case APP_UPDATE_TOKEN: {
      const { value } = action.payload;
      result = {
        ...state,
        token: value
      };
      break;
    }
    case APP_UPDATE_PAYLOAD: {
      const { value } = action.payload;
      result = {
        ...state,
        payload: { ...value }
      };
      break;
    }
    case APP_UPDATE_UNREAD_COUNT: {
      const { value } = action.payload;
      result = {
        ...state,
        unreadCount: value
      };
      break;
    }
    default: {
      result = { ...state };
    }
  }
  return result;
}
