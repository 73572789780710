import { AxiosResponse } from 'axios';

import env from '../../environment.json';
import { axiosInstance } from '../../features/Localization/service/api';
import { IPage, IService } from '../../types';

const cAuthURL = '/v1/authorization/user/google';
export const getAuthToken = async (
  token: string,
  country: string,
  deviceID: string | null,
  oneSignalID: string | null,
  firstname: string | null,
  lastname: string | null
): Promise<{
  token: string;
  userID: number;
  payload: { name: string; surname: string; avatar: string };
} | null> => {
  let result: {
    token: string;
    userID: number;
    payload: { name: string; surname: string; avatar: string };
  } | null = null;
  try {
    const response: AxiosResponse<{
      token: string;
      userID: number;
      payload: { name: string; surname: string; avatar: string };
    }> = await axiosInstance.post(
      // @ts-ignore
      `${env[env.environment].host}${cAuthURL}`,
      {
        token,
        country,
        firstname,
        lastname,
        deviceID,
        oneSignalID,
        globalApp: true
      }
    );
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cCategoryListURL = '/v1/service/list/category';
export const getCategoryList = async (language: string): Promise<{ id: number; country: string; value: string }[]> => {
  let result: { id: number; country: string; value: string }[] = [];
  try {
    const response: AxiosResponse<{ id: number; country: string; value: string }[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cCategoryListURL}?language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cServiceListURL = '/v1/service/list/short';
export const getServiceList = async (language: string): Promise<IService[]> => {
  let result: IService[] = [];
  try {
    const response: AxiosResponse<IService[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cServiceListURL}?country=spain&language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

const cServiceURL = '/v1/service';
export const getService = async (id: number, language: string): Promise<IPage | null> => {
  let result: IPage | null = null;
  try {
    const response: AxiosResponse<IPage> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cServiceURL}?id=${id}&language=${language}`
    );
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
