import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { IElement, ITextElement } from '../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class TextWithIndent extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', borderRadius: 2 }}>
        <Box
          sx={{
            m: 1
          }}></Box>
        <Typography>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
