import { Component } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { MessageListView } from '../../features/MessageListView';
import { TopicRow } from '../../features/TopicRow';
import { selectTopicList } from '../../app/redux/state';
import { HeaderBar } from '../../features/HeaderBar';
import { markReadTopic, sendMessage } from '../../app/redux/actions';
import { useParams } from 'react-router-dom';
import { has } from 'lodash';
import { sendAnalyticsEvent } from '../../service/analytics';

export interface IChatMessage {
  id: number;
  root: boolean;
  topic: string;
  author: string;
  value: string;
  time: string;
}

export interface IChatTopic {
  id: string;
  name: string;
  unreadCount: number;
  data: IChatMessage[];
}

interface Props {
  topic: string | undefined;
  topicList: Record<string, IChatTopic>;
  actions: {
    markReadTopic: (value: string) => void;
    sendMessage: (topic: string, text: string) => void;
  };
}

interface State {
  selectedTopic: string | null;
  topicList: Record<string, IChatTopic>;
}

class ChatConnect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let topicList: Record<string, IChatTopic> = {};
    if (this.props.topic != null && !has(topicList, this.props.topic)) {
      const topic: IChatTopic = {
        id: this.props.topic,
        name: this.props.topic,
        unreadCount: 0,
        data: []
      };
      topicList = { [this.props.topic]: topic };
    }

    this.state = {
      selectedTopic: this.props.topic != null ? this.props.topic : null,
      topicList
    };
  }

  onChangeTopic = (id: string) => {
    sendAnalyticsEvent('bChatSelectConversation');
    if (has(this.props.topicList, id)) {
      this.props.actions.markReadTopic(id);
    }
    this.setState(() => ({ selectedTopic: id }));
  };

  onSendMessage = (value: string) => {
    if (this.state.selectedTopic != null) {
      sendAnalyticsEvent('bChatSend');
      this.props.actions.sendMessage(this.state.selectedTopic, value);
      this.setState(() => ({ topicList: {} }));
    }
  };

  render() {
    const data = { ...this.props.topicList, ...this.state.topicList };
    return (
      <Container maxWidth={false} disableGutters>
        <HeaderBar />
        <Container
          maxWidth={false}
          style={{
            padding: 0
          }}
          sx={{ minHeight: `calc(100vh - 80px)` }}>
          <Grid container sx={{ flexWrap: 'nowrap', minHeight: `calc(100vh - 80px)` }}>
            <Grid item xs={4} md={4} lg={4} sx={{ minWidth: '473px' }}>
              <Box sx={{ maxHeight: '95vh', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                {Object.values(data).map(topic => (
                  <TopicRow
                    key={`topic-${topic.id}`}
                    selected={topic.id === this.state.selectedTopic}
                    topic={topic.name}
                    author={topic.data.length > 0 ? topic.data[topic.data.length - 1].author : null}
                    message={topic.data.length > 0 ? topic.data[topic.data.length - 1].value : null}
                    time={topic.data.length > 0 ? topic.data[topic.data.length - 1].time : null}
                    unreadCount={topic.unreadCount}
                    onClick={() => this.onChangeTopic(topic.id)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={8} md={8} lg={8} sx={{ backgroundColor: '#F3F6FA' }}>
              <MessageListView data={data} selectedTopic={this.state.selectedTopic} onSend={this.onSendMessage} />
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state: never) => ({
  topicList: selectTopicList(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ markReadTopic, sendMessage }, dispatch)
});

const ChatRedux = connect(mapStateToProps, mapDispatchToProps)(ChatConnect);

export const Chat = () => {
  const { topic } = useParams();

  return <ChatRedux topic={topic} />;
};
