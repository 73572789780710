import { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  onClick: () => void;
}

export class TopicIconItem extends PureComponent<Props> {
  render() {
    return (
      <Box
        onClick={this.props.onClick}
        sx={{
          width: 299,
          height: 106,
          display: 'flex',
          background: '#ffffff',
          boxShadow: '0px 4px 24px rgba(67, 68, 69, 0.12)',
          borderRadius: 4,
          p: 2,
          m: '6px',
          padding: '12px 16px',
          ':hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          sx={{
            background: '#ED6D42',
            minWidth: '40px',
            height: '40px',
            borderRadius: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(images/main/bank.svg)',
            backgroundSize: '25px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}>
          {/*<ArticleOutlinedIcon sx={{ color: '#ffffff',  width: '24px',height: '24px', }} />*/}
        </Box>
        <Box sx={{ flexDirection: 'column', pl: 2, width: '239px' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '28px',
              color: '#1B1F3B',
              opacity: 0.8,
              mb: '4px'
            }}>
            {this.props.title}
          </Typography>
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: 'flex'*/}
          {/*  }}>*/}
          {/*  <Typography*/}
          {/*    sx={{*/}
          {/*      fontWeight: 400,*/}
          {/*      fontSize: '17px',*/}
          {/*      lineHeight: '24px',*/}
          {/*      color: '#1B1F3B',*/}
          {/*      opacity: 0.4*/}
          {/*    }}>*/}
          {/*    Когда нужно расплачиваться*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    );
  }
}
