import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IElement, ITextElement } from '../../../types';
import { Bold } from '../Bold';

interface Props {
  value: IElement;
}

export class AttentionBoxWithText extends PureComponent<Props> {
  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          background: '#fef2e0',
          borderRadius: '16px',
          padding: '12px',
          alignItems: 'flex-start'
        }}>
        <InfoOutlinedIcon sx={{ mx: '8px', color: '#F45725' }} />
        <Typography>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
