import { PureComponent } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

import { HeaderBar } from '../../features/HeaderBar';
import { TopicItemList } from '../../features/TopicItemList';
import { IService } from '../../types';
import { selectCategoryList, selectServiceList } from '../../app/redux/state';
import { sendAnalyticsEvent } from '../../service/analytics';

interface Props {
  id: string | undefined;
  categoryList: Record<number, string>;
  serviceList: IService[];
  navigate: NavigateFunction;
}

interface State {
  id: number;
  data: IService[];
}

class TopicListConnect extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: this.props.id != null ? parseInt(this.props.id, 10) : 1,
      data: []
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.id !== this.props.id) {
      this.setState(
        () => ({ id: this.props.id != null ? parseInt(this.props.id, 10) : 1 }),
        () => this.onChangeTopic()
      );
    }
  }

  componentDidMount() {
    this.onChangeTopic();
  }

  onChangeTopic = () => {
    const data: IService[] = [];
    this.props.serviceList.forEach((obj: IService) => {
      if (this.state.id != null && obj.categoryID === this.state.id) {
        data.push(obj);
      }
      this.setState(() => ({ data }));
    });
  };

  openService = (id: number, name: string) => {
    sendAnalyticsEvent('bClickService', { id, name });
    this.props.navigate(`/service/${id}`);
  };

  openCategory = (id: number, name: string) => {
    sendAnalyticsEvent('bClickCategory', { id, name });
    this.props.navigate(`/list/${id}`);
  };

  render() {
    const { id } = this.state;

    if (id == null) {
      return null;
    }

    return (
      <Container disableGutters={true} maxWidth={false} sx={{ height: '100vh' }}>
        <HeaderBar />
        <Box
          sx={{
            height: `calc(100vh - 80px)`,
            flexWrap: 'nowrap'
          }}>
          <Grid container sx={{ height: '100%', flexWrap: 'nowrap' }}>
            <Grid
              item
              xs={3}
              md={3}
              lg={3}
              sx={{
                minWidth: '295px',
                backgroundColor: '#FBFBFA',
                borderRightWidth: '1px',
                borderColor: '#D9D9D9',
                borderRightStyle: 'solid'
              }}>
              <Box sx={{ px: 1, pt: 4 }}>
                {Object.keys(this.props.categoryList).map(key => (
                  <Box
                    sx={{
                      mb: 1,
                      padding: '2px 24px',
                      backgroundColor: id === (key as unknown as number) ? '#F1F1F0' : 'none',
                      borderRadius: '4px',
                      ':hover': {
                        cursor: 'pointer'
                      }
                    }}
                    key={key}
                    onClick={() =>
                      this.openCategory(key as unknown as number, this.props.categoryList[key as unknown as number])
                    }>
                    {id === (key as unknown as number) ? (
                      <Typography
                        sx={{
                          textAlign: 'left',
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '24px',
                          color: '#1B1F3B',
                          opacity: 0.8
                        }}>
                        {this.props.categoryList[key as unknown as number]}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          textAlign: 'left',
                          fontWeight: 400,
                          fontSize: '15px',
                          lineHeight: '24px',
                          color: '#1B1F3B',
                          opacity: 0.4
                        }}>
                        {this.props.categoryList[key as unknown as number]}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={9} md={9} lg={9} sx={{ minWidth: '400px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', pl: '7%' }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '36px',
                    lineHeight: '40px',
                    color: '#1B1F3B',
                    opacity: 0.8,
                    margin: '24px 0 4px 0'
                  }}>
                  {this.props.categoryList[id as unknown as number]}
                </Typography>
                {/*<Typography*/}
                {/*  sx={{*/}
                {/*    fontWeight: 400,*/}
                {/*    fontSize: '17px',*/}
                {/*    lineHeight: '24px',*/}
                {/*    color: '#1B1F3B',*/}
                {/*    opacity: 0.4,*/}
                {/*    mb: '12px'*/}
                {/*  }}>*/}
                {/*  Когда нужно расплачиваться*/}
                {/*</Typography>*/}
                <TopicItemList data={this.state.data} onClick={this.openService} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export const TopicList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const categoryList = useSelector(selectCategoryList);
  const serviceList = useSelector(selectServiceList);

  return <TopicListConnect navigate={navigate} id={id} categoryList={categoryList} serviceList={serviceList} />;
};
