import React, { PureComponent } from 'react';
import { Box } from '@mui/material';

interface Props {
  name: string;
}

export class TopicIcon extends PureComponent<Props> {
  render() {
    const { name } = this.props;
    return (
      <Box
        sx={{
          background: '#F2511B',
          width: '40px',
          height: '40px',
          minWidth: '40px',
          borderRadius: '49px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {name.length > 0 && name[0]}
      </Box>
    );
  }
}
