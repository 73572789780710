import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { Avatar } from '../Avatar';

interface Props {
  colorIndex: number;
  author: string;
  message: string;
  created: string;
  root: boolean;
}

export class Message extends PureComponent<Props> {
  render() {
    const { root } = this.props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: root ? 'flex-end' : 'flex-start',
          mt: 1,
          mb: 1,
          ml: root ? 0 : 1,
          mr: root ? 1 : 0
        }}>
        {!root && <Avatar colorIndex={this.props.colorIndex} name={this.props.author} />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 2,
            pb: 2,
            ml: root ? 0 : 1,
            maxWidth: '65%',
            background: root ? '#343434' : '#FFFFFF',
            borderRadius: 4
          }}>
          <Typography
            sx={{
              ml: 2,
              mr: 2,
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '24px',
              color: root ? '#FFFFFF' : '#1B1F3B',
              opacity: 0.8,
              textAlign: 'justify',
              whiteSpace: 'break-spaces'
            }}>
            {this.props.message}
          </Typography>
          <Typography
            sx={{
              ml: 2,
              mr: 2,
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '11px',
              lineHeight: '13px',
              color: root ? '#FFFFFF' : '#1B1F3B',
              opacity: 0.4,
              textAlign: 'right'
            }}>
            {this.props.created}
          </Typography>
        </Box>
      </Box>
    );
  }
}
