import React, { PureComponent } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';
import { HeaderBarUnauthorized } from '../../../features/HeaderBarUnauthorized';
import { ProgressBar } from '../../../features/ProgressBar';

interface Props {
  navigate: NavigateFunction;
}

class Questionnaire0Connect extends PureComponent<Props> {
  render() {
    return (
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100vh'
        }}>
        <HeaderBarUnauthorized />
        <ProgressBar current={1} count={5} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '702px',
            height: '225px',
            mt: '48px'
          }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '36px',
              lineHeight: '40px',
              color: '#1B1F3B',
              opacity: 0.8,
              mb: '16px'
            }}>
            Привет! Давай займемся твоим переездом в Испанию
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '24px',
              color: '#1B1F3B',
              opacity: 0.65
            }}>
            Мы экономим время и траты при переезде, сотрудничаем с местными компаниями для оформления всех услуг
          </Typography>
          <Button
            onClick={() => this.props.navigate('/questionnaire/1')}
            sx={{
              width: '220px',
              height: '56px',
              backgroundColor: '#ED6D42',
              color: '#FFFFFF',
              fontSize: '17px',
              ':hover': {
                backgroundColor: '#ED6D42'
              },
              boxShadow: '0px 2px 5px #DAC8C8;',
              borderRadius: '10px',
              mt: '24px'
            }}>
            Начать
          </Button>
        </Box>
      </Container>
    );
  }
}

export const Questionnaire0 = () => {
  const navigate = useNavigate();
  return <Questionnaire0Connect navigate={navigate} />;
};
