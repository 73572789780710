import React, { PureComponent } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { selectPayload, selectUnreadCount } from '../../app/redux/state';
import { NickName } from '../NickName';
import { sendAnalyticsEvent } from '../../service/analytics';
import { useTranslate } from '../Localization';
import { ITranslate } from '../Localization/types';
import { refreshCategoryList } from '../../app/redux/actions';

interface Props {
  translate: ITranslate;
  navigate: NavigateFunction;
  payload: { name: string | null; surname: string | null; avatar: string | null };
  location: string;
  unreadCount: number;
  actions: {
    refreshCategoryList: (language: string) => void;
  };
}

class HeaderBarConnect extends PureComponent<Props> {
  openChat = () => {
    sendAnalyticsEvent('bChat');
    this.props.navigate(`/support`);
  };

  render() {
    const { payload, location, unreadCount, translate } = this.props;
    const root = location === '/';
    return (
      <AppBar
        position="static"
        color="transparent"
        sx={{
          borderBottomWidth: root ? 'none' : '1px',
          borderColor: root ? 'none' : '#D9D9D9',
          borderBottomStyle: root ? 'none' : 'solid',
          boxShadow: 'none',
          height: '80px'
        }}>
        <Toolbar sx={{ height: '100%' }}>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              ml: 0
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ':hover': {
                  cursor: 'pointer'
                }
              }}>
              <Box
                onClick={() => this.props.navigate(`/`)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: location === '/' ? '157px' : '140px'
                }}>
                {root ? (
                  <img src="/images/logo.svg" width="44px" height="44px" color="white" alt={'logo'} />
                ) : (
                  <img src="/images/logo.png" width="25px" height="25px" alt={'logo'} />
                )}
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '36px',
                    alignSelf: 'center',
                    textTransform: 'none',
                    ml: 1,
                    color: root ? '#FFFFFF' : '#1B1F3B'
                  }}>
                  Let's Go
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.5,
                    fontSize: '12px',
                    ml: '11px',
                    mt: '5px',
                    color: root ? '#F3E3E3' : '#1B1F3B'
                  }}>
                  {process.env.REACT_APP_VERSION}
                </Typography>
              </Box>
              {/*<LocalizationContext.Consumer>*/}
              {/*  /!*@ts-ignore*!/*/}
              {/*  {({ language, changeLanguage }) => (*/}
              {/*    <SwitchLanguage*/}
              {/*      language={language}*/}
              {/*      changeLanguage={changeLanguage}*/}
              {/*      refresh={this.props.actions.refreshCategoryList}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</LocalizationContext.Consumer>*/}
            </Box>
          </Container>
          <Button
            disableRipple
            variant={'text'}
            sx={{ fontSize: '17px', color: '#1B1F3B', pt: '12px', mr: '16px', minWidth: '166px' }}
            onClick={this.openChat}>
            <Typography sx={{ opacity: root ? 1 : 0.8, color: root ? '#FFFFFF' : '#1B1F3B' }}>
              {translate('desktop_my_appeals')}
            </Typography>
            {unreadCount > 0 && (
              <Box
                sx={{
                  width: '22px',
                  height: '22px',
                  backgroundColor: '#526ED3',
                  borderRadius: '50%',
                  ml: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography sx={{ fontSize: '13px', color: '#FFFFFF', mt: '2px' }}>{unreadCount}</Typography>
              </Box>
            )}
          </Button>
          <Box
            sx={{
              aspectRatio: '1/1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              background: payload.avatar != null ? `url(${payload.avatar})` : '#F3F6FA',
              backgroundSize: '40px',
              mt: '4px',
              mr: '24px',
              ':hover': {
                cursor: 'pointer'
              }
            }}>
            {payload.avatar == null && <NickName surname={payload.surname} name={payload.name}></NickName>}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

export const HeaderBar = () => {
  const navigate = useNavigate();
  const payload = useSelector(selectPayload);
  const location = useLocation();
  const unreadCount = useSelector(selectUnreadCount);
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <HeaderBarConnect
      navigate={navigate}
      payload={payload}
      location={location.pathname}
      unreadCount={unreadCount}
      translate={translate}
      actions={{ refreshCategoryList: (language: string) => dispatch(refreshCategoryList(language)) }}
    />
  );
};
