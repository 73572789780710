import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendAnalyticsEvent } from '../../service/analytics';

export default function DetectChangeLocation() {
  const { pathname } = useLocation();

  useEffect(() => {
    sendAnalyticsEvent('ePageLocation', { url: pathname });
  }, [pathname]);

  return null;
}
