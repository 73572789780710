import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { all } from 'redux-saga/effects';
import { Persistor } from 'redux-persist/es/types';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

import appReducer from './reducer';
import { appSaga } from './saga';

const rootReducer = combineReducers({ app: appReducer });

export default function* rootSaga() {
  yield all([...appSaga]);
}

const persistConfig = {
  version: 1,
  key: 'root',
  storage: storage,
  whitelist: ['app']
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const getStore = (): { store: unknown; persistor: null | Persistor } => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
  });
  //sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store, null, () => sagaMiddleware.run(rootSaga));

  return { store, persistor };
};
