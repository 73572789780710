import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#FFFFFF',
          width: '343px',
          height: '48px',
          borderRadius: '16px',
          fontSize: '17px',
          lineHeight: '24px',
          fontWeight: '400',
          textTransform: 'none',
          ':hover': {
            backgroundColor: '#FFFFFF'
          }
        },
        text: {
          fontSize: '13px',
          lineHeight: '24px',
          fontWeight: '400',
          textTransform: 'none',
          ':hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  }
});
