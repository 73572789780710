import { init as initAmplitude, logEvent, setUserId as setUserIdAmplitude } from '@amplitude/analytics-browser';

export const initAnalytics = () => {
  initAmplitude('5f09ee21b5e6cfee6a2b4be14759d42a', undefined, {
    defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }
  });

  const isFirstStart = localStorage.getItem('FirstStart');
  if (isFirstStart == null) {
    localStorage.setItem('FirstStart', 'true');
    sendAnalyticsEvent('eFirstStart');
  }
};

export const setUserID = (userID: number) => {
  setUserIdAmplitude(`user_${userID}`);
};

export const sendAnalyticsEvent = (name: string, params?: Record<string, string | number | undefined>) => {
  // console.log(name, ':', params);
  logEvent(name, params);
};
