import React, { PureComponent } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { withNavigateHookHOC } from '../../features/hook';
import { selectUserID } from '../../app/redux/state';
import { authorization } from '../../app/redux/actions';
import { HeaderBarUnauthorized } from '../../features/HeaderBarUnauthorized';
import { sendAnalyticsEvent } from '../../service/analytics';

interface InjectedProps {
  navigate: (_path: string, _p: { replace: boolean }) => void;
}

interface Props {
  userID: number | null;
  actions: {
    authorization: (value: string) => void;
  };
}

class LoginConnect extends PureComponent<Props> {
  get injected(): InjectedProps {
    return this.props as Props & InjectedProps;
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<{}>, _snapshot?: any) {
    if (prevProps.userID == null && this.props.userID != null) {
      this.injected.navigate('/', {
        replace: true
      });
    }
  }

  onSuccess = ({ credential }: any) => {
    sendAnalyticsEvent('eRegisterSuccess');
    this.props.actions.authorization(credential);
  };

  render() {
    return (
      <Container maxWidth={false} disableGutters sx={{ minHeight: '100vh' }}>
        <HeaderBarUnauthorized />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '15%' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '36px',
              lineHeight: '40px',
              color: '#1B1F3B',
              opacity: 0.8,
              mb: 3
            }}>
            Пройдите авторизацию
          </Typography>
          <GoogleLogin
            onSuccess={this.onSuccess}
            onError={() => {
              sendAnalyticsEvent('eRegisterError');
              alert('Ошибка работы с Google');
            }}
            useOneTap
          />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state: never) => ({
  userID: selectUserID(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ authorization }, dispatch)
});

export const Login = withNavigateHookHOC(connect(mapStateToProps, mapDispatchToProps)(LoginConnect));
