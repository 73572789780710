import React, { Component } from 'react';

import { RadioButton } from '../RadioButton';

interface Props {
  data: string[];
  onSelect: (value: string | null) => void;
}

interface State {
  selected: number;
}

export class RadioButtonGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: -1
    };
  }

  onClick = (index: number) =>
    this.setState(
      prev => ({
        selected: prev.selected !== index ? index : -1
      }),
      () => this.props.onSelect(this.state.selected !== -1 ? this.props.data[this.state.selected] : null)
    );

  render() {
    const { data } = this.props;
    const { selected } = this.state;
    return (
      <>
        {data.map((value, index) => (
          <RadioButton key={value} value={value} selected={index === selected} onClick={() => this.onClick(index)} />
        ))}
      </>
    );
  }
}
