import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

export const cAvatarColorList: string[] = [
  '#A8CEF1',
  '#3682DB',
  '#8DDA71',
  '#34B41F',
  '#E29398',
  '#B8474E',
  '#FCC068',
  '#FF8A00',
  '#DAB3F9',
  '#7B439E',
  '#FEE797',
  '#FCBB14',
  '#EA97C4',
  '#BD65A4',
  '#7FD7CC',
  '#2FAD96',
  '#D4ACA2',
  '#9D6F64',
  '#D2E9A2',
  '#AADC42',
  '#A0C5DF'
];

interface Props {
  colorIndex: number;
  name: string | null;
  selected: boolean;
  onClick: () => void;
}

export class Avatar extends PureComponent<Props> {
  render() {
    const { colorIndex, name, selected, onClick } = this.props;
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 3, mr: 2 }} onClick={onClick}>
        <Box
          sx={{
            mr: '11px',
            background: selected ? '#526ED3' : 'transparent',
            width: '5px',
            height: '52px',
            borderRadius: '0px 4px 4px 0px'
          }}></Box>
        <Box
          sx={{
            background: `${cAvatarColorList[colorIndex]}`,
            width: '52px',
            height: '52px',
            borderRadius: selected ? '8px' : '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '24px',
              color: '#000000',
              textTransform: 'uppercase'
            }}>
            {name != null && name.length > 0 && name[0]}
          </Typography>
        </Box>
      </Box>
    );
  }
}
