import { REHYDRATE } from 'redux-persist/es/constants';

import { IChatTopic } from '../../pages/chat';
import { IPage, IService } from '../../types';
import { IAppState } from './state';

export const APP_UPDATE_TOPIC_LIST = 'app/UPDATE_TOPIC_LIST';
export const APP_AUTHORIZATION = 'app/APP_AUTHORIZATION';
export const APP_UPDATE_USER_ID = 'app/UPDATE_USER_ID';
export const APP_REFRESH_CATEGORY_LIST = 'app/REFRESH_CATEGORY_LIST';
export const APP_UPDATE_CATEGORY_LIST = 'app/UPDATE_CATEGORY_LIST';
export const APP_UPDATE_SERVICE_LIST = 'app/APP_UPDATE_SERVICE_LIST';
export const APP_REFRESH_SERVICE = 'app/REFRESH_SERVICE';
export const APP_UPDATE_SERVICE = 'app/UPDATE_SERVICE';
export const APP_UPDATE_TOKEN = 'app/UPDATE_TOKEN';
export const APP_UPDATE_PAYLOAD = 'app/UPDATE_PAYLOAD';
export const APP_UPDATE_UNREAD_COUNT = 'app/UPDATE_UNREAD_COUNT';
export const APP_MARK_READ_TOPIC = 'app/MARK_READ_TOPIC';
export const APP_SEND_MESSAGE = 'app/SEND_MESSAGE';

export interface IAppUpdateTopicListAction {
  type: typeof APP_UPDATE_TOPIC_LIST;
  payload: {
    data: Record<string, IChatTopic>;
  };
}

export interface IAppAuthorizationAction {
  type: typeof APP_AUTHORIZATION;
  payload: {
    value: string;
  };
}

export interface IAppUpdateUserIdAction {
  type: typeof APP_UPDATE_USER_ID;
  payload: {
    value: number | null;
  };
}

export interface IAppRefreshCategoryListAction {
  type: typeof APP_REFRESH_CATEGORY_LIST;
  payload: {
    language: string;
  };
}

export interface IAppUpdateCategoryListAction {
  type: typeof APP_UPDATE_CATEGORY_LIST;
  payload: {
    data: Record<number, string>;
  };
}

export interface IAppUpdateServiceListAction {
  type: typeof APP_UPDATE_SERVICE_LIST;
  payload: {
    data: IService[];
  };
}

export interface IAppRefreshServiceAction {
  type: typeof APP_REFRESH_SERVICE;
  payload: {
    value: number;
    language: string;
  };
}

export interface IAppUpdateServiceAction {
  type: typeof APP_UPDATE_SERVICE;
  payload: {
    value: IPage | null;
  };
}

export interface IAppUpdateTokenAction {
  type: typeof APP_UPDATE_TOKEN;
  payload: {
    value: string | null;
  };
}

export interface IAppUpdatePayloadAction {
  type: typeof APP_UPDATE_PAYLOAD;
  payload: {
    value: { name: string | null; surname: string | null; avatar: string | null };
  };
}

export interface IAppUpdateUnreadCountAction {
  type: typeof APP_UPDATE_UNREAD_COUNT;
  payload: {
    value: number;
  };
}

export interface IAppMarkReadTopicAction {
  type: typeof APP_MARK_READ_TOPIC;
  payload: {
    value: string;
  };
}

export interface IAppSendMessageAction {
  type: typeof APP_SEND_MESSAGE;
  payload: {
    topic: string;
    text: string;
  };
}

export interface IAppRehydrateAction {
  type: typeof REHYDRATE;
  payload: {
    app: IAppState;
  };
}

export type IAppActionTypes =
  | IAppUpdateTopicListAction
  | IAppAuthorizationAction
  | IAppUpdateUserIdAction
  | IAppRefreshCategoryListAction
  | IAppUpdateCategoryListAction
  | IAppUpdateServiceListAction
  | IAppRefreshServiceAction
  | IAppUpdateServiceAction
  | IAppUpdateTokenAction
  | IAppUpdatePayloadAction
  | IAppUpdateUnreadCountAction
  | IAppMarkReadTopicAction
  | IAppSendMessageAction
  | IAppRehydrateAction;

export const updateTopicList = (data: Record<string, IChatTopic>): IAppUpdateTopicListAction => ({
  type: APP_UPDATE_TOPIC_LIST,
  payload: { data }
});

export const authorization = (value: string): IAppAuthorizationAction => ({
  type: APP_AUTHORIZATION,
  payload: { value }
});

export const updateUserID = (value: number | null): IAppUpdateUserIdAction => ({
  type: APP_UPDATE_USER_ID,
  payload: { value }
});

export const refreshCategoryList = (language: string): IAppRefreshCategoryListAction => ({
  type: APP_REFRESH_CATEGORY_LIST,
  payload: { language }
});

export const updateCategoryList = (data: Record<number, string>): IAppUpdateCategoryListAction => ({
  type: APP_UPDATE_CATEGORY_LIST,
  payload: { data }
});

export const updateServiceList = (data: IService[]): IAppUpdateServiceListAction => ({
  type: APP_UPDATE_SERVICE_LIST,
  payload: { data }
});

export const refreshService = (value: number, language: string): IAppRefreshServiceAction => ({
  type: APP_REFRESH_SERVICE,
  payload: { value, language }
});

export const updateService = (value: IPage | null): IAppUpdateServiceAction => ({
  type: APP_UPDATE_SERVICE,
  payload: { value }
});

export const updateToken = (value: string | null): IAppUpdateTokenAction => ({
  type: APP_UPDATE_TOKEN,
  payload: { value }
});

export const updatePayload = (value: {
  name: string | null;
  surname: string | null;
  avatar: string | null;
}): IAppUpdatePayloadAction => ({
  type: APP_UPDATE_PAYLOAD,
  payload: { value }
});

export const updateUnreadCount = (value: number): IAppUpdateUnreadCountAction => ({
  type: APP_UPDATE_UNREAD_COUNT,
  payload: { value }
});

export const markReadTopic = (value: string): IAppMarkReadTopicAction => ({
  type: APP_MARK_READ_TOPIC,
  payload: { value }
});

export const sendMessage = (topic: string, text: string): IAppSendMessageAction => ({
  type: APP_SEND_MESSAGE,
  payload: { topic, text }
});
