import { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  value: string;
  onClick: () => void;
}

export class TopicItem extends PureComponent<Props> {
  render() {
    return (
      <Box
        onClick={this.props.onClick}
        sx={{
          width: 620,
          display: 'flex',
          background: '#ffffff',
          boxShadow: '0px 4px 24px rgba(67, 68, 69, 0.12)',
          borderRadius: 4,
          p: 2,
          mt: 2,
          mr: 2,
          ':hover': {
            cursor: 'pointer'
          }
        }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '17px',
            lineHeight: '24px',
            color: '#1B1F3B',
            opacity: 0.8
          }}>
          {this.props.value}
        </Typography>
      </Box>
    );
  }
}
