import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Persistor } from 'redux-persist/es/types';
import { Provider } from 'react-redux';

import { TopicList } from '../../pages/topicList';
import { Service } from '../../pages/service';
import { Chat } from '../../pages/chat';
import { getStore } from '../redux/store';
import { Main } from '../../pages/main';
import { Login } from '../../pages/login';
import { RequireAuth } from '../../features/RequireAuth';
import { PersistGate } from 'redux-persist/integration/react';
import { Questionnaire0 } from '../../pages/questionnaire/0';
import { Questionnaire1 } from '../../pages/questionnaire/1';
import { Questionnaire2 } from '../../pages/questionnaire/2';
import { Questionnaire3 } from '../../pages/questionnaire/3';
import { Questionnaire4 } from '../../pages/questionnaire/4';
import { initAnalytics } from '../../service/analytics';
import { LocalizationContextProvider } from '../../features/Localization';
import DetectChangeLocation from '../../features/DetectChangeLocation';

interface Props {}

interface State {
  store: unknown;
  persistor: Persistor | null;
  loading: boolean;
}

export class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { store, persistor } = getStore();
    this.state = {
      store,
      persistor,
      loading: false
    };

    initAnalytics();
  }

  render() {
    return (
      // @ts-ignore
      <Provider store={this.state.store}>
        <LocalizationContextProvider>
          {this.state.persistor != null && (
            <PersistGate loading={null} persistor={this.state.persistor}>
              <DetectChangeLocation />
              {!this.state.loading && (
                <Routes>
                  <Route path="/questionnaire" Component={Questionnaire0} />
                  <Route path="/questionnaire/1" Component={Questionnaire1} />
                  <Route path="/questionnaire/2" Component={Questionnaire2} />
                  <Route path="/questionnaire/3" Component={Questionnaire3} />
                  <Route path="/questionnaire/4" Component={Questionnaire4} />
                  <Route path="/login" Component={Login} />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <Main />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/support/:topic?"
                    element={
                      <RequireAuth>
                        <Chat />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/list/:id"
                    element={
                      <RequireAuth>
                        <TopicList />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/service/:id"
                    element={
                      <RequireAuth>
                        <Service />
                      </RequireAuth>
                    }
                  />
                </Routes>
              )}
            </PersistGate>
          )}
        </LocalizationContextProvider>
      </Provider>
    );
  }
}
