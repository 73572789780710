export interface IMessageAdditional {
  version: number;
  userInfo: {
    nickname: string;
    oneSignalID: string;
  };
}

export class MessageAdditional implements IMessageAdditional {
  public version: number;
  public userInfo: {
    nickname: string;
    oneSignalID: string;
    country: string;
  };

  constructor(userInfo: { nickname: string; oneSignalID: string; country: string }) {
    this.version = 2;
    this.userInfo = userInfo;
  }
}

export interface IMessage {
  version: number;
  id: number;
  state: number;
  nickname: string;
  userIdFrom: number | null;
  deviceIdFrom: string;
  userIdTo: number | null;
  deviceIdTo: string;
  topic: string;
  value: string;
  created: string;
  updated: string;
  additional: IMessageAdditional | null;
}

export class Message implements IMessage {
  public version: number;
  public id: number;
  public state: number;
  public nickname: string;
  public userIdFrom: number | null;
  public deviceIdFrom: string;
  public userIdTo: number | null;
  public deviceIdTo: string;
  public topic: string;
  public value: string;
  public created: string;
  public updated: string;
  public additional: IMessageAdditional | null;

  constructor(
    id: number,
    state: number,
    nickname: string,
    userIdFrom: number | null,
    deviceIdFrom: string,
    userIdTo: number | null,
    deviceIdTo: string,
    topic: string,
    value: string,
    created: string,
    updated: string,
    additional: IMessageAdditional | null
  ) {
    this.version = 1;
    this.id = id;
    this.state = state;
    this.nickname = nickname;
    this.userIdFrom = userIdFrom;
    this.deviceIdFrom = deviceIdFrom;
    this.userIdTo = userIdTo;
    this.deviceIdTo = deviceIdTo;
    this.topic = topic;
    this.value = value;
    this.created = created;
    this.updated = updated;
    this.additional = additional;
  }
}

export interface IService {
  id: number;
  country: string;
  categoryID: number;
  category: string;
  name: string;
  manager: string;
  updated: string;
}

export interface ITextElement {
  id: string;
  value: string;
  type: 'normal' | 'bold';
}

export interface IElement {
  id: string;
  type: string;
  title: null | string;
  attention: boolean;
  index: number;
  text: ITextElement[];
  children: IElement[];
}

export interface IPage {
  id: string;
  categoryID: number;
  category: string;
  topic: string;
  icon: string;
  name: string;
  header: string;
  children: IElement[];
}

export class TextElement {
  id: string;
  value: string;
  type: 'normal' | 'bold';

  constructor(id: string, value: string, type: 'normal' | 'bold' = 'normal') {
    this.id = id;
    this.value = value;
    this.type = type;
  }
}
