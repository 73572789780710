import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { IElement, ITextElement } from '../../../types';
import { Bold } from '../Bold';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
  value: IElement;
}

export class TextWithPlot extends PureComponent<Props> {
  render() {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircleIcon sx={{ fontSize: '10px', mr: '12px', color: '#696A6C' }}></CircleIcon>
        <Typography sx={{ fontSize: '17px', lineHeight: '24px', fontWeight: '400' }}>
          {this.props.value.text.map((txt: ITextElement) =>
            txt.type === 'bold' ? <Bold key={txt.id}>{txt.value}</Bold> : txt.value
          )}
        </Typography>
      </Box>
    );
  }
}
