import React, { Component } from 'react';
import { has } from 'lodash';

import { CheckBoxButton } from '../CheckBoxButton';

interface Props {
  data: string[];
  onSelect: (data: (string | undefined)[]) => void;
}

interface State {
  selected: Record<number, boolean>;
}

export class CheckBoxButtonGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: {}
    };
  }

  onClick = (index: number) =>
    this.setState(
      prev => ({
        selected: {
          ...prev.selected,
          [index]: has(prev.selected, index) ? !prev.selected[index] : true
        }
      }),
      () =>
        this.props.onSelect(
          Object.keys(this.state.selected).reduce((acc: string[], key: string) => {
            if (this.state.selected[key as unknown as number]) {
              acc.push(this.props.data[key as unknown as number]);
            }
            return acc;
          }, [])
        )
    );

  render() {
    const { data } = this.props;
    const { selected } = this.state;
    return (
      <>
        {data.map((value, index) => (
          <CheckBoxButton
            key={value}
            value={value}
            selected={has(selected, index) && selected[index]}
            onClick={() => this.onClick(index)}
          />
        ))}
      </>
    );
  }
}
