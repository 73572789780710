import { useContext, createContext, useState, PropsWithChildren, useEffect } from 'react';
import { has } from 'lodash';

import { ILocalizationContext, ITranslate } from './types';
import { getLocalization } from './service/api';

export const LocalizationContext = createContext<ILocalizationContext>({
  language: 'ru',
  data: {},
  changeLanguage: (_language: string) => {},
  getWord: (_key: string): string => ''
});

export const LocalizationContextProvider = ({ children }: PropsWithChildren) => {
  const [language, changeLanguage] = useState<string>('ru');
  const [dictionary, setDictionary] = useState<Record<string, Record<string, string>>>({});
  const getWord = (key: string) => {
    let result = key;
    if (has(dictionary, key)) {
      result = dictionary[key][language];
    }
    return result;
  };
  useEffect(() => {
    getLocalization().then(setDictionary);
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        changeLanguage,
        data: dictionary,
        getWord
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useTranslate = (): ITranslate => {
  const { language, data } = useContext(LocalizationContext);
  return (key: string) => {
    let result = key;
    if (has(data, key)) {
      result = data[key][language];
    }
    return result;
  };
};
