import { channel } from 'redux-saga';
import { put, takeEvery, takeLatest, select, call } from 'redux-saga/effects';
import { has } from 'lodash';
import { DateTime } from 'luxon';
import { io } from 'socket.io-client';

import { IMessage, IPage, IService, Message, MessageAdditional } from '../../types';
import {
  APP_AUTHORIZATION,
  APP_MARK_READ_TOPIC,
  APP_REFRESH_CATEGORY_LIST,
  APP_REFRESH_SERVICE,
  APP_SEND_MESSAGE,
  IAppAuthorizationAction,
  IAppMarkReadTopicAction,
  IAppRefreshCategoryListAction,
  IAppRefreshServiceAction,
  IAppSendMessageAction,
  updateCategoryList,
  updatePayload,
  updateService,
  updateServiceList,
  updateToken,
  updateTopicList,
  updateUnreadCount,
  updateUserID
} from './actions';

import env from '../../environment.json';
import { IChatMessage, IChatTopic } from '../../pages/chat';
import { selectPayload, selectToken, selectTopicList, selectUnreadCount, selectUserID } from './state';
import { setUserID } from '../../service/analytics';
import { getAuthToken, getCategoryList, getService, getServiceList } from '../../service/api';

const historyChannel = channel();
const messageChannel = channel();
const successSendMessage = channel();

// @ts-ignore
const socket = io(env[env.environment].socket, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

export const appSaga = [
  init(),
  takeEvery(historyChannel, onParseHistory),
  takeEvery(APP_AUTHORIZATION, onAuthorization),
  takeEvery(APP_REFRESH_CATEGORY_LIST, onRefreshCategoryList),
  takeEvery(APP_REFRESH_SERVICE, onRefreshService),
  takeEvery(APP_MARK_READ_TOPIC, onMarkReadTopic),
  takeEvery(messageChannel, onReceiveMessage),
  takeLatest(APP_SEND_MESSAGE, onSendMessage),
  takeEvery(successSendMessage, onSuccessSendMessage)
];

function* init() {
  socket.on('connect', () => {
    console.log('connect:', socket.connected);
  });

  socket.io.on('error', error => {
    console.log(error);
  });

  socket.on('history', (msg: any) => historyChannel.put(msg));
  socket.on('message', (msg: any) => messageChannel.put(msg));

  const token: string | null = yield select(selectToken);
  if (token != null) {
    socket.auth = { token };
    socket.connect();
  }
}

function* onParseHistory(data: IMessage[]) {
  let unreadCount = 0;
  const topicList: Record<string, IChatTopic> = {};

  data
    .sort((a, b) => (DateTime.fromISO(a.created).toMillis() < DateTime.fromISO(b.created).toMillis() ? 1 : -1))
    .forEach(obj => {
      const topic = obj.topic.trim();
      const dateTime = DateTime.fromISO(obj.created);

      if (topic.length > 0) {
        if (!has(topicList, topic)) {
          topicList[topic] = {
            id: topic,
            name: topic,
            unreadCount: 0,
            data: []
          };
        }
        unreadCount += obj.state === 0 ? 1 : 0;
        topicList[topic].unreadCount += obj.state === 0 ? 1 : 0;
        topicList[topic].data.unshift(parseMessage(obj, dateTime));
      }
    });

  yield put(updateTopicList(topicList));
  yield put(updateUnreadCount(unreadCount));
}

const parseMessage = (obj: IMessage, dateTime: DateTime): IChatMessage => ({
  id: obj.id,
  root: obj.userIdFrom === -1,
  topic: obj.topic,
  author: obj.userIdFrom === -1 ? 'Вы' : obj.nickname.trim().length > 0 ? obj.nickname.trim() : 'Unknown',
  value: obj.value,
  time:
    dateTime.toISOWeekDate() === DateTime.now().toISOWeekDate()
      ? dateTime.toFormat('HH:mm')
      : dateTime.toFormat('dd.MM.yyyy')
});

function* onAuthorization(action: IAppAuthorizationAction) {
  const data: {
    token: string;
    userID: number;
    payload: { name: string; surname: string; avatar: string };
  } | null = yield call(getAuthToken, action.payload.value, 'spain', null, null, null, null);
  if (data != null) {
    setUserID(data.userID);
    yield put(updateToken(data.token));
    yield put(updateUserID(data.userID));
    yield put(updatePayload(data.payload));

    socket.disconnect();
    socket.auth = { token: data.token, username: 'desktop' };
    socket.connect();
  }
}

function* onRefreshCategoryList(action: IAppRefreshCategoryListAction) {
  const categoryListTemp: { id: number; country: string; value: string }[] = yield call(
    getCategoryList,
    action.payload.language
  );
  const categoryList: Record<number, string> = {};
  categoryListTemp.forEach(({ id, value }: any) => {
    categoryList[id] = value;
  });
  yield put(updateCategoryList(categoryList));

  const serviceList: IService[] = yield call(getServiceList, action.payload.language);
  yield put(updateServiceList(serviceList));
}

function* onRefreshService(action: IAppRefreshServiceAction) {
  yield put(updateService(null));

  const service: IPage | null = yield call(getService, action.payload.value, action.payload.language);
  yield put(updateService(service));
}

function* onMarkReadTopic(action: IAppMarkReadTopicAction) {
  const userID: number | null = yield select(selectUserID);
  if (userID != null) {
    socket.emit('readTopic', userID, action.payload.value);

    const topicList: Record<string, IChatTopic> = yield select(selectTopicList);
    let unreadInTopic = topicList[action.payload.value].unreadCount;
    yield put(
      updateTopicList({
        ...topicList,
        [action.payload.value]: {
          ...topicList[action.payload.value],
          unreadCount: 0
        }
      })
    );

    const unreadCount: number = yield select(selectUnreadCount);
    yield put(updateUnreadCount(unreadCount - unreadInTopic));
  }
}

function* onReceiveMessage(value: IMessage) {
  if (value.topic.length > 0) {
    const dateTime = DateTime.fromISO(value.created);
    const topicList: Record<string, IChatTopic> = yield select(selectTopicList);
    if (!has(topicList, value.topic)) {
      const obj = {
        id: value.topic,
        name: value.topic,
        unreadCount: value.state === 0 ? 1 : 0,
        data: [parseMessage(value, dateTime)]
      };
      yield put(
        updateTopicList({
          [obj.id]: { ...obj },
          ...topicList
        })
      );
    } else {
      yield put(
        updateTopicList({
          ...topicList,
          [value.topic]: {
            ...topicList[value.topic],
            data: [...topicList[value.topic].data, parseMessage(value, dateTime)],
            unreadCount: topicList[value.topic].unreadCount + value.state === 0 ? 1 : 0
          }
        })
      );
    }
  }

  if (value.state === 0) {
    const unreadCount: number = yield select(selectUnreadCount);
    yield put(updateUnreadCount(unreadCount + 1));
  }
}

function* onSendMessage({ payload }: IAppSendMessageAction) {
  const userID: number | null = yield select(selectUserID);
  const deviceID = `desktop${userID}`;
  const userPayload: { name: string | null; surname: string | null; avatar: string | null } = yield select(
    selectPayload
  );

  const message: IMessage = new Message(
    DateTime.now().toMillis(),
    1,
    userPayload.name != null ? userPayload.name : '',
    userID,
    deviceID,
    -1,
    'root',
    payload.topic,
    payload.text,
    DateTime.now().toISO(),
    DateTime.now().toISO(),
    new MessageAdditional({
      nickname: userPayload.name != null ? userPayload.name : '',
      oneSignalID: '',
      //@ts-ignore
      country: env.country
    })
  );
  socket.emit('message', 'root', message, (response: boolean) => {
    if (response) {
      const value = {
        id: message.id,
        root: false,
        topic: message.topic,
        author: message.nickname,
        value: message.value,
        time: DateTime.now().toFormat('HH:mm')
      };
      successSendMessage.put(value);
    }
  });
}

function* onSuccessSendMessage(value: IChatMessage) {
  const topicList: Record<string, IChatTopic> = yield select(selectTopicList);
  if (!has(topicList, value.topic)) {
    const obj = {
      id: value.topic,
      name: value.topic,
      unreadCount: 0,
      data: [value]
    };
    yield put(
      updateTopicList({
        [obj.id]: { ...obj },
        ...topicList
      })
    );
  } else {
    yield put(
      updateTopicList({
        ...topicList,
        [value.topic]: {
          ...topicList[value.topic],
          data: [...topicList[value.topic].data, value]
        }
      })
    );
  }
}
