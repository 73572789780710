import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  value: string;
  selected: boolean;
  onClick: () => void;
}

export class RadioButton extends PureComponent<Props> {
  render() {
    const { selected, value, onClick } = this.props;
    return selected ? (
      <Box
        onClick={onClick}
        sx={{
          width: '343px',
          height: '56px',
          backgroundColor: '#333333',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          mb: '8px',
          ':hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: '#FFFFFF',
            m: '0 18px 0 24px'
          }}></Box>
        <Typography sx={{ fontSize: '17px', fontWeight: 400, color: '#FFFFFF' }}>{value}</Typography>
      </Box>
    ) : (
      <Box
        onClick={onClick}
        sx={{
          width: '343px',
          height: '56px',
          border: '1px solid #D7D7D7',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          mb: '8px',
          ':hover': {
            cursor: 'pointer'
          }
        }}>
        <Box
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            border: '1px solid #D7D7D7',
            m: '0 10px 0 16px'
          }}></Box>
        <Typography sx={{ fontSize: '17px', fontWeight: 400, color: '#1B1F3B', opacity: 0.8 }}>{value}</Typography>
      </Box>
    );
  }
}
