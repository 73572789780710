import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

import { cAvatarColorList } from '../../../Avatar';

interface Props {
  colorIndex: number;
  name: string;
}

export class Avatar extends PureComponent<Props> {
  render() {
    const { colorIndex, name } = this.props;
    return (
      <Box
        sx={{
          background: `${cAvatarColorList[colorIndex]}`,
          width: '36px',
          height: '36px',
          borderRadius: '42px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '24px',
            color: '#1B1F3B',
            opacity: 0.8,
            textTransform: 'uppercase'
          }}>
          {name[0]}
        </Typography>
      </Box>
    );
  }
}
